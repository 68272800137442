import { createTheme, ThemeOptions } from '@mui/material/styles';
import { common } from '@mui/material/colors';

/* ThemeOptions interface changes are being defined in theme.d.ts */
const GreenTheme: ThemeOptions = createTheme({
  palette: {
    mode: 'dark',
    common: {
      black: common.black,
      white: common.white,
    },
    primary: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    error: {
      main: '#b71c1c',
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    warning: {
      main: '#ff9800',
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: '#fff',
    },
    info: {
      main: '#2196f3',
      light: '#64b5f6',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    success: {
      main: '#8bc34a',
      light: '#aed581',
      dark: '#689f38',
      contrastText: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#f5f5f5',
      level1: "linear-gradient(rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08))",
      level2: '#333333',
      footer: '#111',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)', // Primary text color on light background
      secondary: 'rgba(0, 0, 0, 0.75)', // Secondary text color on light background
      disabled: 'rgba(0, 0, 0, 0.38)', // Disabled text color on light background
      hint: 'rgba(0, 0, 0, 0.38)', // Hint text color on light background
      icon: 'rgba(0, 0, 0, 0.54)', // Icon color on light background
      divider: 'rgba(0, 0, 0, 0.12)', // Divider color on light background
      // and other possible text colors you may want to define
    },
  },
  status: {
    danger: '#1976d2'
  },
});

export default GreenTheme;