import { createTheme, ThemeOptions } from '@mui/material/styles';
import { common } from '@mui/material/colors';

/* ThemeOptions interface changes are being defined in theme.d.ts */
const YellowTheme: ThemeOptions = createTheme({
  palette: {
    mode: 'dark',
    common: {
      black: common.black,
      white: common.white
    },
    primary: {
      main: '#F5CB5C',
      light: '#FFF8E1',
      dark: '#BFAE51',
      contrastText: '#000',
    },
    secondary: {
      main: '#3F51B5',
      light: '#757de8',
      dark: '#002984',
      contrastText: '#fff',
    },
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    warning: {
      main: '#ff9800',
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: '#fff',
    },
    info: {
      main: '#2196f3',
      light: '#64b5f6',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    success: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c',
      contrastText: '#fff',
    },
    text: {
      primary: '#212121',
      secondary: '#757575',
      disabled: '#BDBDBD',
      hint: '#BDBDBD',
      icon: 'rgba(255, 255, 255, 0.54)', // Icon color on dark background
      divider: 'rgba(255, 255, 255, 0.12)', // Divider color on dark background
      // and other possible text colors you may want to define
    },
    background: {
      paper: '#fff',
      default: '#f5f5f5',
      level1: "linear-gradient(rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08))",
      level2: '#333333',
      footer: '#111',
    }
  },
  status: {
    danger: '#1976d2'
  },
});

export default YellowTheme;