import { DeleteOutline } from "@mui/icons-material";
import { Box, Container, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { NameObject } from "../../../util/namesParserUtil";
import {
  CheckboxListSecondary,
  CheckboxListOptionType,
} from "../../molecules/CheckboxListSecondary";
import { nameListDefaultValue } from "../CreateName";

const Names = () => {
  const [myNameList, setMyNameList] = useState<NameObject[]>([]);
  const hasFavorites = myNameList.length > 0;

  useEffect(() => {
    const storedNames = localStorage.getItem("myNames");
    if (storedNames) {
      const parsedNames: NameObject[] = JSON.parse(storedNames);
      setMyNameList(parsedNames);
    } else {
      setMyNameList(nameListDefaultValue);
    }
  }, []);

  const handleCheckboxMyListOnChange = (
    keyName: string,
    value: CheckboxListOptionType[]
  ) => {
    // TODO: Improvise this logic
    const filtered = myNameList.filter((item) => item.id !== value[0]);
    setMyNameList(filtered);
    localStorage.setItem("myNames", JSON.stringify([...myNameList]));
  };

  return (
    <Container disableGutters maxWidth="sm" sx={{height: "calc(100vh - 180px)" }}>
      <Paper elevation={3}>
        {!hasFavorites && (<Box sx={{ mb: 5 }} p={{xs: 2, sm: 4}}>
          <Typography
            variant="h6"
            component="h3"
            color="textSecondary"
            sx={{mt:2}}
          >
            Favorite Baby Names
          </Typography>

          <Stack alignItems="center" justifyContent="center" sx={{height: "calc(100vh - 330px)" }}>
            <Typography
              variant="body2"
              component="p"
              color="textSecondary"
            >
              You have not yet added any names to your favorites. 
            </Typography>
          </Stack>
        </Box>)}
        {hasFavorites && (<Box sx={{ mb: 5 }} p={{xs: 2, sm: 4}} height= "calc(100vh - 215px)">
          <CheckboxListSecondary
            label="Favorite Baby Names"
            icon={<DeleteOutline fontSize="medium" />}
            keyName="myNameList"
            nameList={myNameList}
            onChange={handleCheckboxMyListOnChange}
          />
        </Box>)}
      </Paper>
    </Container>
  );
};

export default Names;
