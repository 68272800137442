import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';

const Input = styled(MuiInput)`
  width: 42px;
`;

function valuetext(value: number) {
  return `${value}`;
}

export type inputSliderValueType = string | number | Array<number | string>;

interface InputSliderProps {
  icon: React.ReactNode,
  keyName: string,
  label: string,
  step: number,
  min: number,
  max: number,
  inputBoxVisible?: boolean
  defaultValue: number,
  handleInputSliderChange: (keyName: string, value: inputSliderValueType) => void
}

export function InputSlider({keyName, icon, label, step, min, max, defaultValue, inputBoxVisible = false, handleInputSliderChange} : InputSliderProps) {
  const [value, setValue] = React.useState<inputSliderValueType>(defaultValue);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue);
    handleInputSliderChange(keyName, newValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value === '' ? '' : Number(event.target.value);
    setValue(newValue);
    handleInputSliderChange(keyName, newValue);
  };

  const handleBlur = () => {
    if (value < min) {
      setValue(min);
    } else if (value > max) {
      setValue(max);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Typography id="input-slider" color="textSecondary" gutterBottom>
        {label}
      </Typography>
      <Grid container spacing={2} alignItems="center" sx={{marginTop: 2}}>
        <Grid item>
          {icon}
        </Grid>
        <Grid item xs >
          <Slider
            aria-label="Alphabet count"
            defaultValue={defaultValue}
            getAriaValueText={valuetext}
            valueLabelDisplay="on"
            step={step}
            marks
            min={min}
            max={max}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            value={typeof value === 'number' ? value : defaultValue}
          />
        </Grid>
        {inputBoxVisible && (<Grid item>
          <Input
            value={value}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: step,
              min: min,
              max: max,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>)}
      </Grid>
    </Box>
  );
}