import axios, { AxiosRequestConfig } from "axios";
import { BASE_API_URL } from "../constants";

export interface apiResponseErrorProps {
  title: string;
  message: string;
  stackTrace: string;
}

export interface ApiResponse<T> {
  data: T;
  success: boolean;
  error?: apiResponseErrorProps;
}

export type BaseUrlType = string | undefined;

interface CRUDHeaders {
  [key: string]: string;
}

interface CRUDConfig extends AxiosRequestConfig {
  timeout?: number;
}

export class CRUDUtility {
  private baseURL: BaseUrlType;
  private headers: CRUDHeaders;
  private othersProps: CRUDConfig;

  constructor(baseURL: BaseUrlType = BASE_API_URL, headers: CRUDHeaders = {}) {
    this.baseURL = baseURL;
    this.headers = headers;
    this.othersProps = {
      timeout: 30000, // Timeout in milliseconds (30 seconds)
    };
  }

  private mergeConfig(headers: CRUDHeaders = {}, timeout?: number): CRUDConfig {
    const config: CRUDConfig = {
      headers: { ...this.headers, ...headers },
      ...this.othersProps,
    };
    if (timeout !== undefined) {
      config.timeout = timeout;
    }
    return config;
  }

  async post<T, R>(resource: string, data: T, headers?: CRUDHeaders, timeout?: number ): Promise<ApiResponse<R | null>> {
    try {
      const config = this.mergeConfig(headers, timeout);
      const response = await axios.post(`${this.baseURL}/api/${resource}`, data, config);
      return { data: response.data, success: true };
    } catch (error) {
      return { data: null, success: false, error: error as apiResponseErrorProps };
    }
  }

  async get<T>( resource: string, headers?: CRUDHeaders, timeout?: number ): Promise<ApiResponse<T | null>> {
    try {
      const config = this.mergeConfig(headers, timeout);
      const response = await axios.get( `${this.baseURL}/api/${resource}`, config);
      return { data: response.data, success: true };
    } catch (error) {
      return { data: null, success: false, error: error as apiResponseErrorProps };
    }
  }

  async update<T>( resource: string, id: string | number, data: T, headers?: CRUDHeaders, timeout?: number ): Promise<ApiResponse<T | null>> {
    try {
      const config = this.mergeConfig(headers, timeout);
      const response = await axios.put( `${this.baseURL}/api/${resource}/${id}`, data, config);
      return { data: response.data, success: true };
    } catch (error) {
      return { data: null, success: false, error: error as apiResponseErrorProps };
    }
  }

  async delete<T>(resource: string, id: string | number, headers?: CRUDHeaders, timeout?: number ): Promise<ApiResponse<T | null>> {
    try {
      const config = this.mergeConfig(headers, timeout);
      const response = await axios.delete(`${this.baseURL}/api/${resource}/${id}`, config);
      return { data: response.data, success: true };
    } catch (error) {
      return { data: null, success: false, error: error as apiResponseErrorProps };
    }
  }
}

/*

  const crudUtility = new CRUDUtility('https://example.com/api', {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${process.env.API_Token}`
  });

  const { data, success, error } = await crudUtility.read('users');
  if( success ) {
    console.log(data);
  } else {
    console.log(error);
  }
  console.log(response.data);

  const user = { name: 'John Doe', email: 'johndoe@example.com' };
  const { data, success, error } = await crudUtility.post('users', user);
  if( success ) {
    console.log(data);
  } else {
    console.log(error);
  }

*/
