import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Names from "./components/organisms/Names";
import AssignmentIcon from '@mui/icons-material/Assignment';
import CreateName from './components/organisms/CreateName';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';

type iconType=  OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
  muiName: string;
}

export type RouteObjectProps = {
  index: boolean,
  label: string,
  path: string,
  routeName: string,
  component: React.ReactNode,
  icon: iconType
}

export const NamesAppRoutes: RouteObjectProps[] = [
  {
    index: true,
    label: "Find Baby Names",
    path: "",
    routeName: '/',
    component: <CreateName />,
    icon: AddCircleOutlineOutlinedIcon,
  },
  { 
    index: false,
    label: "Favorite Baby Names",
    path: "mynames",
    routeName: '/mynames',
    component: <Names />, 
    icon: AssignmentIcon,
  },
];