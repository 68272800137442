import { createTheme, ThemeOptions } from '@mui/material/styles';
import { common } from '@mui/material/colors';

/* ThemeOptions interface changes are being defined in theme.d.ts */
const DarkTheme: ThemeOptions = createTheme({
  palette: {
    mode: 'dark',
    common: {
      black: common.black,
      white: common.white,
    },
    primary: {
      main: '#90caf9',
      light: '#e3f2fd',
      dark: '#42a5f5',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f48fb1',
      light: '#ffbdcb',
      dark: '#bf5f82',
      contrastText: '#fff',
    },
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    warning: {
      main: '#ff9800',
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: '#fff',
    },
    info: {
      main: '#2196f3',
      light: '#64b5f6',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    success: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.87)', // Primary text color on dark background
      secondary: 'rgba(255, 255, 255, 0.75)', // Secondary text color on dark background
      disabled: 'rgba(255, 255, 255, 0.38)', // Disabled text color on dark background
      hint: 'rgba(255, 255, 255, 0.38)', // Hint text color on dark background
      icon: 'rgba(255, 255, 255, 0.54)', // Icon color on dark background
      divider: 'rgba(255, 255, 255, 0.12)', // Divider color on dark background
      // and other possible text colors you may want to define
    },
    background: {
      paper: '#121212',
      default: '#121212',
      level1: "linear-gradient(rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08))",
      level2: '#333333',
      footer: '#111',
    },
  },
  status: {
    danger: '#1976d2'
  },
});

export default DarkTheme;