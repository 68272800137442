import Layout from "../../organisms/Layout/Layout";
import MainContent from "../../organisms/Layout/MainContent";
import SideNavBar from "../../organisms/Layout/SideNavBar";
import { NamesAppRoutes } from "../../../routes";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/system";
import { Stack } from "@mui/material";
import BasicSpeedDial from "../../molecules/SpeedDial";
import SideBarFooter from "../../organisms/Layout/SideBarFooter";

interface NamesAppPageProps {
  onChangeTheme: (value: string) => void;
  themeName: string
}

const NamesAppPage = ({ onChangeTheme, themeName } : NamesAppPageProps) => {
  const getRoutes = () => {
    return [...NamesAppRoutes].map(({ path, component, index }) =>
      index ? (
        <Route index={true} element={component} key={path}></Route>
      ) : (
        <Route index={false} path={path} element={component} key={path}></Route>
      )
    );
  };
  return (
    <Stack sx={{"position": "relative"}}>
      <Layout SideNavBar={SideNavBar} onChangeTheme={onChangeTheme} themeName={themeName}>
        <Box>
          <MainContent />
          <Routes>{getRoutes()}</Routes>
          <Box width={{xs: "100%", md: "calc(100%)"}} sx={{bottom: 0}}>
            <SideBarFooter />
          </Box>
        </Box>
      </Layout>
      <Box display="none">
        <BasicSpeedDial />
      </Box>
    </Stack>
  );
};

export default NamesAppPage;
