import { Box } from "@mui/system";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  RouteObjectProps,
  NamesAppRoutes,
} from "../../../routes";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import SideBarFooter from "./SideBarFooter";

export type SideNavBarProps = {
  open: boolean;
};

const SideNavBar: React.FC<SideNavBarProps> = ({ open }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const createRoutes = (routes: RouteObjectProps[]) => {
    return (
      <>
        <List>
          {routes.map((route) => (
            <ListItem
              key={route.label}
              disablePadding
              sx={{ display: "block" }}
              onClick={() => navigate(route.routeName)}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {<route.icon color={location.pathname === route.routeName ? "success" : "inherit"}/>}
                </ListItemIcon>
                <ListItemText
                  primary={<Typography variant="subtitle1" color={location.pathname === route.routeName ? "success" : "inherit"} >{route.label}</Typography>}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Box display="none">
          {open && (<SideBarFooter />)}
        </Box>
      </>
    );
  };
  return (
    <Box>
      {createRoutes(NamesAppRoutes)}
    </Box>
  );
};

export default SideNavBar;
