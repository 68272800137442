import { Typography } from "@mui/material"
import { Box } from "@mui/system"

const MainContent: React.FC = () => {
  return (
    <Box>
      <Typography variant="h3" component="h2" align="center" sx={{ mb: 2, display: "none"}} color="secondary">Names App </Typography>
    </Box>
  )
}

export default MainContent;