import { IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo50.png";
import { styled } from '@mui/material/styles';

const SPANBLUE = styled('span')`
  color: #1da3fe;
`;

type LogoProps = {
  color?: String
  justifyContent?: string,
  open: boolean
}

const Logo= ({ color, justifyContent, open } : LogoProps) => {
  const navigate = useNavigate();
  return (
    <IconButton
      disableRipple
      size="large"
      edge="start"
      aria-label="logo"
      sx={{ flexGrow: 1, p: 0, m: 0, color: `${color}`, justifyContent:`${justifyContent}` }}
      onClick={() => navigate("/")}
    >
      <img src={logo} alt="Baby name generator interface powered by ChatGPT AI" width="50px" height="50px"/>
      <Typography
        variant="body1"
        component="h1"
        sx={{
          pl: 1,
          fontWeight: 700,
          letterSpacing: ".05rem",
          color: `${color}`
        }}
      >
        <span>Name</span>
        <SPANBLUE>Advisor</SPANBLUE>
        <span>AI</span>
      </Typography>
    </IconButton>
  );
};

export default Logo;
