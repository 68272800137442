import * as React from 'react';
import { useState } from 'react';
import { TextField, Collapse, List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, ListItemButton, IconButton, Snackbar, Alert } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { CRUDUtility } from "../../apis/CRUDUtility";
import { stringAvatar } from '../../util/avatarUtil';
import { styled } from "@mui/material/styles";
import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';

interface SearchResult {
  name: string;
  origin: string;
  meaning: string;
  description: string;
}

const BoldSpan = styled("span")`
  font-weight: 500
`;

const SearchField = () => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult | null>(null);
  const [expanded, setExpanded] = useState(false);
  const api = new CRUDUtility();

  const handleSearch = async () => {
    try {
      setApiError(false);
      setLoading(true);
      setSearchResults(null);
      const response: any = await api.get<any>(`names?q=${searchText}`);
      const { success, error, data } = response;
      setLoading(false);
      if (success) {
        if (data !== null) {
          const parsedData = JSON.parse(data.answer);
          setSearchResults(parsedData);
          setExpanded(!!response.data);
        } else {
          // Handle error scenarios 
          setApiError(true);
          console.log('Name Search data is null');
        }
      } else {
        // Handle error here
        setApiError(true);
        console.log(error);
      }
    } catch (error) {
      setLoading(false);
      setApiError(true);
      console.error(error);
    }
  };

  const handleClear = () => {
    setSearchText('');
    setSearchResults(null);
    setExpanded(false);
    setApiError(false);
  };

  const handleSnackbarClose = () => {
    setApiError(false);
  };

  const getListItem = (name: SearchResult, index: number) => {
    const { name: babyName, meaning, description, origin } = name;
    return (<React.Fragment>
      <ListItemAvatar>
        <Avatar
          alt={`Avatar n°${index + 1}`}
          {...stringAvatar(babyName)}
        />
      </ListItemAvatar>
      <ListItemText
        primary={babyName}
        secondary={
          <React.Fragment>
            <Typography color="text.secondary" variant="body2" component="div">
               <BoldSpan>Meaning:  </BoldSpan>{meaning}
            </Typography>
            <Typography color="text.secondary" variant="body2" component="div">
               <BoldSpan>Origin:  </BoldSpan>{origin}
            </Typography>
            {description && (<Typography sx={{ mb: 1 }} color="text.secondary" variant="body2" component="div">
               <BoldSpan>Description:  </BoldSpan>{description}
            </Typography>)}
          </React.Fragment>
        }
      />
    </React.Fragment>)
  }

  return (
    <>
      <Stack direction="row">
        <TextField
          fullWidth
          label="Search name"
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleSearch();
            }
          }}
          InputProps={{
            endAdornment: (
              <>
                {searchText && (
                  <IconButton onClick={handleClear}>
                    <ClearIcon />
                  </IconButton>
                )}
              </>
            ),
          }}
        />
        <LoadingButton
          variant="contained"
          onClick={handleSearch}
          loading={loading}
          color="primary"
          sx={{ml: 1}}
        >
          <SearchIcon />
        </LoadingButton>
      </Stack>
      <Collapse in={expanded || apiError}>
        {searchResults !== null &&  (<List>
          <ListItem>
            <ListItemButton disableGutters>
              {getListItem(searchResults, 1)}
            </ListItemButton>
          </ListItem>
        </List>)}

       {apiError && ( <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%', my: 4}}>
          No results were found for the term you searched. Please check your spelling and try again
        </Alert>)}
      </Collapse>
    </>
  );
};

export default SearchField;
