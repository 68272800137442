import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { ThemeProvider } from '@mui/material/styles';
import LightTheme from "./themes/lightTheme";
import DarkTheme from "./themes/darkTheme";
import GreenTheme from "./themes/greenTheme";
import PinkTheme from "./themes/pinkTheme";
import YellowTheme from "./themes/yellowTheme";
import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import NamesAppPage from "./components/pages/NamesAppPage";
import { useState } from "react";
import { THEMES } from "./constants";

const THEME_DEFAULT = THEMES.LIGHT;

const getTheme = (themeName: string) => {
  switch (themeName) {
    case THEMES.LIGHT:
      return LightTheme;
    case THEMES.DARK:
      return DarkTheme;
    case THEMES.GREEN:
      return GreenTheme;
    case THEMES.PINK:
      return PinkTheme;
    case THEMES.YELLOW:
      return YellowTheme;
  
    default:
      return LightTheme;
  }
}

function App() {
  const [themeName, setThemeName] = useState(THEME_DEFAULT);
  const theme = getTheme(themeName);
  const onChangeTheme = (themeName: string) => {
    setThemeName(themeName);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<NamesAppPage onChangeTheme={onChangeTheme} themeName={themeName}/> }></Route>
          <Route path="/*" element={<NamesAppPage onChangeTheme={onChangeTheme} themeName={themeName}/>}></Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
