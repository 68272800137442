import { createTheme, ThemeOptions } from '@mui/material/styles';
import { common } from '@mui/material/colors';

/**
 * primary color is a vibrant pink (#FF4081)
 * secondary color is a vibrant teal (#4DB6AC)
 * error color is a vibrant red (#F44336)
 * warning color is a vibrant orange (#FF9800)
 * info color is a vibrant blue (#2196F3)
 * success color is a vibrant green (#4CAF50)
 */

/* ThemeOptions interface changes are being defined in theme.d.ts */
const PinkTheme: ThemeOptions = createTheme({
  palette: {
    mode: 'light',
    common: {
      black: common.black,
      white: common.white,
    },
    primary: {
      main: '#FF4081',
      light: '#FF79B0',
      dark: '#C60055',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#4DB6AC',
      light: '#82E9DE',
      dark: '#00867D',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#F44336',
      light: '#FF7961',
      dark: '#BA000D',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FF9800',
      light: '#FFC947',
      dark: '#C66900',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#2196F3',
      light: '#6EC6FF',
      dark: '#0069C0',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#4CAF50',
      light: '#80E27E',
      dark: '#087F23',
      contrastText: '#FFFFFF',
    },
    background: {
      paper: '#fff',
      default: '#fff',
      level1: '#fff',
      level2: '#333333',
      footer: '#111',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)', // Primary text color on light background
      secondary: 'rgba(0, 0, 0, 0.75)', // Secondary text color on light background
      disabled: 'rgba(0, 0, 0, 0.38)', // Disabled text color on light background
      hint: 'rgba(0, 0, 0, 0.38)', // Hint text color on light background
      icon: 'rgba(0, 0, 0, 0.54)', // Icon color on light background
      divider: 'rgba(0, 0, 0, 0.12)', // Divider color on light background
      // and other possible text colors you may want to define
    }
  },
  status: {
    danger: '#1976d2'
  },
});

export default PinkTheme;