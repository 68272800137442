import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ExpandableSectionProps {
  children: React.ReactNode
}

export const ExpandableSection: React.FC<ExpandableSectionProps> = (props) => {
  const { children } = props;
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="filter-panel-content" id="filter-panel-header">
        <Typography>Advanced options</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* Additional filter options */}
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
