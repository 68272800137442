import Copyright from "../../molecules/Copyright";
import { styled } from '@mui/material/styles';
import { Box, Divider, IconButton, Stack } from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const SideBarFooterBox = styled(Box)(({ theme }) => ({
  bottom: 0,
  background: theme.palette.common.black,
  color: theme.palette.common.white,
  height: "100px",
  width: "100%"
}));

export default function SideBarFooter(props: any) {
  return (
    <SideBarFooterBox>
      <Divider></Divider>
      <Copyright />
      <Divider sx={{mx: 4 }}></Divider>
      <Stack direction="row" justifyContent="center" >
        <IconButton aria-label="twitter" sx={{color: "common.white"}} href="https://twitter.com/sameer_makhija" target="_blank">
          <TwitterIcon />
        </IconButton>
        <IconButton aria-label="linkedin" sx={{color: "common.white"}} href="https://www.linkedin.com/in/sameer-makhija/" target="_blank">
          <LinkedInIcon />
        </IconButton>
        <IconButton aria-label="share" sx={{color: "common.white"}} href="https://linktr.ee/sameer_makhija" target="_blank">
          <ShareIcon />
        </IconButton>
      </Stack>
    </SideBarFooterBox>
  );
}