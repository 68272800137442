import { v4 as uuidv4 } from 'uuid';

export interface NameObject {
  name: string;
  meaning: string;
  origin: string;
  gender: string;
  id: string;
}

// const nameString = " [{\"name\":\"Aadan\",\"meaning\":\"God is Judge\",\"origin\":\"Arabic\"},{\"name\":\"Brenin\",\"meaning\":\"King\",\"origin\":\"Welsh\"},{\"name\":\"Caleb\",\"meaning\":\"Wholehearted\",\"origin\":\"Hebrew\"}]"
export const convertNamesToObjects = (namesString: string, gender: string): NameObject[] => {
  const nameObjects =  JSON.parse(namesString).map((names:NameObject | NameObject[]) => {
    if (Array.isArray(names)) {
      return names.map(name => ({...name, gender, "id": uuidv4()}));
    } else {
      return ({...names, gender, "id": uuidv4()});
    }
  });
  return nameObjects;
}

// const nameString = " Diya--Lamp--Indian,Yogesh--Lord of Yoga--Indian,Divyansh--Divine Part--Indian,Ishan--Lord Shiva--Indian,Dhruv--Pole Star--Indian"
export const convertNamesToObjectsParser = (namesString: string, gender: string): NameObject[] => {
  if (namesString.indexOf("1.") !== -1 ) {
    return parser1(namesString, gender);
  } else {
    return parser2(namesString, gender);
  }
}

// const nameString = "\n    1. Aarush--First Ray of Sunlight--Hindi\n    2. Aniket--God of the Whole Universe--Sanskrit\n    3. Devansh--Part of God--Sanskrit\n    4. Gaurav--Respect--Sanskrit\n    5. Hrithik--Joyful--Sanskrit\n    6. Ishan--Lord Shiva--Sanskrit\n    7. Kartik--Brave--Sanskrit\n    8. Lakshay--Aim--Sanskrit\n    9. Mohit--Attractive--Sanskrit\n    10. Nakul--One of Pandavas--Sanskrit\n    11. Omkar--God's Name--Sanskrit\n    12. Pranav--Om Symbol--Sanskrit\n    13. Rohan--Ascending--Sanskrit\n    14. Shreyas--Fortunate--Sanskrit\n    15. Tejas--Brightness--Sanskrit\n    16. Ujjwal--Brilliant--Sanskrit\n    17. Varun--God of Water--Sanskrit\n    18. Yash--Fame--Sanskrit\n    19. Zayan--Beauty--Sanskrit\n    20. Arjun--Hero of Mahabharata--Sanskrit"
export const parser1 = (namesString: string, gender: string): NameObject[] => {
  // split the input string by \n and remove the empty strings
  const items = namesString.split("\n").filter((item) => item.trim() !== "");

  // create an empty array to hold the final result
  const result: { name: string; meaning: string; origin: string; gender: string; id: string }[] = [];

  // iterate over each item and extract the relevant information
  for (const item of items) {
    const record = item.split(".")[1];
    const [name, meaning, origin] = record.split("--");
    result.push({ name: name.trim(), meaning: meaning.trim(), origin: origin.trim(), gender, id: uuidv4() });
  }

  // print the final result
  // console.log(result);

  return result;
}

// const nameString = " Diya--Lamp--Indian,Yogesh--Lord of Yoga--Indian,Divyansh--Divine Part--Indian,Ishan--Lord Shiva--Indian,Dhruv--Pole Star--Indian"
export const parser2 = (namesString: string, gender: string): NameObject[] => {
  // split the input string by \n and remove the empty strings
  const items = namesString.split(",").filter((item) => item.trim() !== "");

  // create an empty array to hold the final result
  const result: { name: string; meaning: string; origin: string; gender: string; id: string }[] = [];

  // iterate over each item and extract the relevant information
  for (const item of items) {
    const [name, meaning, origin] = item.split("--");
    result.push({ name: name.trim(), meaning, origin, gender, id: uuidv4() });
  }

  // print the final result
  // console.log(result);

  return result;
}