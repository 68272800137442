import { createTheme, ThemeOptions } from '@mui/material/styles';
import { common } from '@mui/material/colors';

/**
 * primary color is a vibrant pink (#FF4081)
 * secondary color is a vibrant teal (#4DB6AC)
 * error color is a vibrant red (#F44336)
 * warning color is a vibrant orange (#FF9800)
 * info color is a vibrant blue (#2196F3)
 * success color is a vibrant green (#4CAF50)
 */

/* ThemeOptions interface changes are being defined in theme.d.ts */
const LightTheme: ThemeOptions = createTheme({
  palette: {
    mode: 'light',
    common: {
      black: common.black,
      white: common.white,
    },
    primary: {
      // main: '#3f51b5',
      main: '#000',
      light: '#757de8',
      dark: '#002984',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#f50057',
      light: '#ff4081',
      dark: '#c51162',
      contrastText: '#000',
    },
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    warning: {
      main: '#ff9800',
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: '#fff',
    },
    info: {
      main: '#2196f3',
      light: '#64b5f6',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    success: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c',
      contrastText: '#fff',
    },
    text: {
      primary: '#212121',
      secondary: '#757575',
      disabled: '#BDBDBD',
      hint: '#BDBDBD',
      icon: 'rgba(0, 0, 0, 0.54)', // Icon color on light background
      divider: 'rgba(0, 0, 0, 0.12)', // Divider color on light background
      // and other possible text colors you may want to define
    },
    background: {
      paper: '#fff',
      default: '#f5f5f5',
      level1: '#fff',
      level2: '#333333',
      footer: '#111',
    }
  },
  status: {
    danger: '#1976d2'
  },
});

export default LightTheme;


/*

Example for consumption

import { useTheme } from '@mui/material/styles';

function MyComponent() {
  const theme = useTheme();

  return (
    <div style={{ backgroundColor: theme.palette.background.default }}>
      <h1 style={{ color: theme.palette.primary.main }}>Hello World!</h1>
    </div>
  );
}

*/