import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import ShareIcon from '@mui/icons-material/Share';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const actions = [
  { icon: <TwitterIcon />, name: 'Twitter', href: 'https://twitter.com/sameer_makhija' },
  { icon: <LinkedInIcon />, name: 'LinkedIn', href: 'https://www.linkedin.com/in/sameer-makhija/' },
  { icon: <ShareIcon />, name: 'Share', href: 'https://linktr.ee/sameer_makhija' },
];

export default function BasicSpeedDial() {
  const handleSpeedActionClick = (href: string) => {
    window.open(href, '_blank');
  }

  return (
    <Box sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 99999 }}>
      <SpeedDial
        ariaLabel="SpeedDial"
        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={(event)=> handleSpeedActionClick(action.href)}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}
