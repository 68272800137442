import React from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { TextField, Typography, Autocomplete } from '@mui/material';
import { makeStyles } from '@mui/styles';

export interface MultiSelectOptionType {
  label: string;
  value: string;
}

export type MultiSelectOptionOutputType = string;

interface MultiSelectProps {
  keyName: string
  label: string;
  placeholder?: string;
  options: MultiSelectOptionType[];
  value?: MultiSelectOptionType[];
  onChange?: (keyName: string, value: MultiSelectOptionType[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    input: {
      marginTop: theme.spacing(1),
    },
  })
);

export const MultiSelect: React.FC<MultiSelectProps> = (props) => {
  // const classes = useStyles();
  const { keyName, options, label, placeholder, value, onChange } = props;
  // const [value, setValue] = useState<MultiSelectOptionType[]>(defaultValue || []);

  const handleOnChange = (event: React.ChangeEvent<{}>, newValue: MultiSelectOptionType[]) => {
    // setValue(newValue);
    if (onChange) {
      onChange(keyName, newValue);
    }
  };

  const getOptionLabel = (option: MultiSelectOptionType) => option.label;
  const isOptionEqualToValue = (option: MultiSelectOptionType, value: MultiSelectOptionType | null) => {
    if (!value) {
      return false;
    }
    return option.value === value.value;
  };

  return (
    // <div className={classes.root}>
    <div>
      <Typography color="textSecondary">{label}</Typography>
      <Autocomplete
        multiple
        options={options}
        value={value}
        onChange={handleOnChange}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeholder}
            // className={classes.input}
          />
        )}
      />
    </div>
  );
};