import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { CRUDUtility, ApiResponse } from "../../../apis/CRUDUtility";
import { CheckboxListSecondary, CheckboxListOptionType } from "../../molecules/CheckboxListSecondary";
import { convertNamesToObjects, NameObject } from "../../../util/namesParserUtil";
import { LoadingButton } from "@mui/lab";
import { InputSlider, inputSliderValueType} from "../../molecules/InputSlider";
import { REGION_PROMPT, RELIGION_PROMPT, MEANING_PROMPT, KEYWORDS, RELIGIONS, CULTURES, COUNTRIES, ALPHABETS, LANGUAGES, ZODIAC_SIGNS, NAME_PROMPTS, NAME_PROMPTS2, COUNTRIES_DEFAULTS, KEYWORDS_DEFAULTS, CULTURES_DEFAULTS, RELIGIONS_DEFAULTS, ALPHABETS_DEFAULTS, ZODIAC_SIGNS_DEFAULTS, LANGUAGES_DEFAULTS, NAME_PROMPT, TWIN_NAMES_PROMPT } from "../../../constants";
import { MultiSelect, MultiSelectOptionType } from "../../molecules/MultiSelect";
import SearchIcon from '@mui/icons-material/Search';
import AbcIcon from '@mui/icons-material/Abc';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CountrySelect, CountrySelectOptionType } from "../../molecules/CountrySelect";
import { FavoriteBorder, DeleteOutline } from "@mui/icons-material";
import { ExpandableSection } from "../../molecules/ExpandableSection";
import SearchField from "../../molecules/SearchField";

export interface NameFormData {
  cultures: MultiSelectOptionType[],
  languages: MultiSelectOptionType[],
  zodiacSigns: MultiSelectOptionType[],
}

export interface NamesApiResponseData {
  answer: string
}

export const nameListDefaultValue = [];

const CreateName = () => {
  const [apiError, setApiError] = useState(false);
  const [myNameList, setMyNameList] = useState<NameObject[]>([]);
  const [nameList, setNameList] = useState<NameObject[]>([]);
  const [loading, setLoading] = useState(false);
  const [titleValue, setTitleValue] = React.useState<string | null>(null);
  const [titleInputValue, setTitleInputValue] = React.useState<string>("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const nameListSectionRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isTwins, setIsTwins] = React.useState(false);
  const [gender, setGender] = React.useState<string>("Boy");
  const [region, setRegion] = React.useState<CountrySelectOptionType | null>(COUNTRIES_DEFAULTS.optionValue);
  const [religions, setReligions] = React.useState<MultiSelectOptionType[]>(RELIGIONS_DEFAULTS.output);
  const [keywords, setKeywords] = React.useState<MultiSelectOptionType[]>(KEYWORDS_DEFAULTS.output);
  const [startingAlphabets, setStartingAlphabets] = React.useState<MultiSelectOptionType[]>(ALPHABETS_DEFAULTS.output);
  const [alphabetCount, setAlphabetCount] = React.useState<inputSliderValueType>(7);
  const [numberOfRecords, setNumberOfRecords] = React.useState<inputSliderValueType>(20);

  const [cultures, setCultures] = React.useState<MultiSelectOptionType[]>(CULTURES_DEFAULTS.output);
  const [languages, setLanguages] = React.useState<MultiSelectOptionType[]>(LANGUAGES_DEFAULTS.output);
  const [zodiacSigns, setZodiacSigns] = React.useState<MultiSelectOptionType[]>(ZODIAC_SIGNS_DEFAULTS.output);

  const api = new CRUDUtility();

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768); // change the breakpoint as per your requirement
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const storedNames = localStorage.getItem('myNames');
    if (storedNames) {
      const parsedNames: NameObject[] = JSON.parse(storedNames);
      setMyNameList(parsedNames);
    } else {
      setMyNameList(nameListDefaultValue);
    }
  }, []);

  useEffect(() => {
    if (isMobile && dataLoaded && nameListSectionRef.current) {
      nameListSectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setDataLoaded(false);
    }
  }, [dataLoaded, isMobile]);

  useEffect(() => {
    if (isTwins) {
      setGender("Boy-Boy");
    } else {
      setGender("Boy");
    }
    setTitleValue(null);
    setTitleInputValue("");
  }, [isTwins]);

  useEffect(() => {
    if (titleInputValue === REGION_PROMPT) {
      setReligions(RELIGIONS_DEFAULTS.output);
      setKeywords(KEYWORDS_DEFAULTS.output);
    } else if (titleInputValue === RELIGION_PROMPT) {
      setRegion(COUNTRIES_DEFAULTS.optionValue);
      setKeywords(KEYWORDS_DEFAULTS.output);
    } else if (titleInputValue === MEANING_PROMPT) {
      setRegion(COUNTRIES_DEFAULTS.optionValue);
      setReligions(RELIGIONS_DEFAULTS.output);
    }
  }, [titleInputValue]);

  const submitFormData = async () => {
    setApiError(false);
    // Submit form data to server
    setLoading(true);
    const inputData = {
      gender,
      alphabetCount,
      numberOfRecords,
      "region": region?.label  || "",
      "religions": religions.map(({value}) => value) || [],
      "startingAlphabets": startingAlphabets.map(({value}) => value) || [],
      "keywords": !isTwins ? keywords.map(({value}) => value) || [] : [],
      "title": isTwins ? TWIN_NAMES_PROMPT : NAME_PROMPT
    };
    const response: ApiResponse<NamesApiResponseData | null > = await api.post<any, NamesApiResponseData>("names", inputData);
    setLoading(false);
    const { success, error, data } = response;
    if (success) {
      if (data !== null) {
        const getConvetedNameObjects = convertNamesToObjects(data.answer, gender);
        setNameList([...getConvetedNameObjects]);
        setDataLoaded(true);
        // navigate("/names");
      } else {
        // Handle error scenarios 
        setApiError(true);
        console.log('Response data is null');
      }
    } else {
      // Handle error here
      setApiError(true);
      console.log(error);
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitFormData();
  };


  const handleFormFieldChange = (keyName: string, value: any) => {
    switch (keyName) {
      case "gender":
        setGender(value);
        break;
      case "region":
        setRegion(value);
        break;
      case "religions":
        setReligions(value);
        break;
      case "keywords":
        setKeywords(value);
        break;
      case "startingAlphabets":
        setStartingAlphabets(value);
        break;
      case "alphabetCount":
        setAlphabetCount(value);
        break;
      case "numberOfRecords":
        setNumberOfRecords(value);
        break;
      case "cultures":
        setCultures(value);
        break;
      case "languages":
        setLanguages(value);
        break;
      case "zodiacSigns":
        setZodiacSigns(value);
        break;
      default:
        break;
    }
  }

  const handleCheckboxListOnChange = (keyName: string, value: CheckboxListOptionType[]) => {
    // TODO: Improvise this logic
    let addToFav = {} as NameObject;
    const filtered = nameList.filter(item => {
      let itemId;
      if(Array.isArray(item)) {
        itemId = item[0].id;
      } else {
        itemId = item.id;
      }
      const hasMatch = itemId === value[0];
      if (hasMatch) {
        addToFav = item;
      }
      return !hasMatch;
    });
    setNameList(filtered);
    setMyNameList([...myNameList, addToFav]);
    // save latest update to local storage
    localStorage.setItem('myNames', JSON.stringify([...myNameList, addToFav]));
  };

  const handleCheckboxMyListOnChange = (keyName: string, value: CheckboxListOptionType[]) => {
    // TODO: Improvise this logic
    const filtered = myNameList.filter(item => {
      if(Array.isArray(item)) {
        return item[0].id !== value[0];
      }
      return item.id !== value[0];
    });
    setMyNameList(filtered);
    localStorage.setItem('myNames', JSON.stringify([...filtered]));
  };

  const handleSnackbarClose = () => {
    setApiError(false);
  };

  const handleIsTwinsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTwins(event.target.checked);
  };

  return (
    <Container disableGutters>
      <Box sx={{ minHeight: "calc(100vh - 228px)", mb: 5 }}>
        <Stack direction={{"xs": "column", "md": "row"}} margin={0} alignItems="flex-start" justifyContent="center">
          <Box component="section" mr={{"xs": 0, "md": 2}} minWidth={{"xs": "100%", "md": "450px", "lg": "600px"}} maxWidth={{"xs": "100%", "md": "450px", "lg": "600px"}} className="form-section">
            <Paper elevation={3}>
              <Box px={{xs: 2, sm: 4}} py={4} pb={0} height="200">
                <SearchField />
              </Box>
              <Divider sx={{mt:2}}>OR</Divider>
              <Box px={{xs: 2, sm: 4}} py={4}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    variant="h6"
                    component="h2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Find my baby's name
                  </Typography>
                  <Stack direction="row" spacing={.5} alignItems="center">
                    <Typography>Single</Typography>
                    <Switch
                      checked={isTwins}
                      onChange={handleIsTwinsChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography>Twins</Typography>
                  </Stack>
                </Stack>

                <Box sx={{mt:2}} className="title">
                  <Typography color="textSecondary">Category</Typography>
                  <Autocomplete
                    value={titleValue}
                    onChange={(event: any, newValue: string | null) => {
                      setTitleValue(newValue);
                    }}
                    inputValue={titleInputValue}
                    onInputChange={(event, newInputValue) => {
                      setTitleInputValue(newInputValue);
                    }}

                    options={isTwins ? NAME_PROMPTS2 : NAME_PROMPTS}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Category"
                        placeholder="Select category"
                        variant="outlined"
                      />
                    )}
                  />
                </Box>

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  
                  {titleInputValue === REGION_PROMPT && (<Box sx={{mt:2}} className="region">
                    <CountrySelect
                      keyName="region"
                      options={COUNTRIES}
                      label="Region"
                      placeholder="Search region"
                      defaultInputValue={COUNTRIES_DEFAULTS.inputValue}
                      defaultOptionValue={region}
                      onChange={handleFormFieldChange}
                    />
                  </Box>)}

                  {titleInputValue === RELIGION_PROMPT && (<Box sx={{mt:2}} className="religions">
                    <MultiSelect
                      value={religions}
                      keyName="religions"
                      options={RELIGIONS}
                      label="Religion"
                      placeholder="Search religion"
                      onChange={handleFormFieldChange}
                    />
                  </Box>)}

                  {titleInputValue === MEANING_PROMPT && !isTwins && (<Box sx={{mt:2}} className="keywords">
                    <MultiSelect
                      value={keywords}
                      keyName="keywords"
                      options={KEYWORDS}
                      label="Keywords"
                      placeholder="Search keywords"
                      onChange={handleFormFieldChange}
                    />
                  </Box>)}

                  {!isTwins && (<Box sx={{mt:2}} className="starting-alphabets">
                    <MultiSelect
                      value={startingAlphabets}
                      keyName="startingAlphabets"
                      options={ALPHABETS}
                      label="Alphabets"
                      placeholder="Search alphabets"
                      onChange={handleFormFieldChange}
                    />
                  </Box>)}

                  <Box sx={{mt:2, display: "none"}} className="cultures">
                    <MultiSelect
                      value={cultures}
                      keyName="cultures"
                      options={CULTURES}
                      label="Cultures"
                      placeholder="Search cultures"
                      onChange={handleFormFieldChange}
                    />
                  </Box>

                  <Box sx={{mt: 2}} className="gender">
                    <FormControl fullWidth >
                      <FormLabel id="demo-controlled-radio-buttons-group">
                        Gender
                      </FormLabel>
                      {!isTwins && (<RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="gender"
                        value={gender}
                        onChange={(event) => handleFormFieldChange("gender", event.target.value)}
                      >
                        <FormControlLabel
                          value="Boy"
                          control={<Radio />}
                          label="Boy"
                        />
                        <FormControlLabel
                          value="Girl"
                          control={<Radio />}
                          label="Girl"
                        />
                        <FormControlLabel
                          value="Unisex"
                          control={<Radio />}
                          label="Unisex"
                        />
                      </RadioGroup>)}
                      {isTwins && (<RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="gender"
                        value={gender}
                        onChange={(event) => handleFormFieldChange("gender", event.target.value)}
                      >
                        <FormControlLabel
                          value="Boy-Boy"
                          control={<Radio />}
                          label="Boy-Boy"
                        />
                        <FormControlLabel
                          value="Girl-Girl"
                          control={<Radio />}
                          label="Girl-Girl"
                        />
                        <FormControlLabel
                          value="Boy-Girl"
                          control={<Radio />}
                          label="Boy-Girl"
                        />
                      </RadioGroup>)}
                    </FormControl>  
                  </Box>

                  <Box sx={{mt: 2}} className="expandable-section-box">
                    <ExpandableSection>
                      <Box sx={{mt:2, display: "none"}} className="languages">
                        <MultiSelect
                          value={languages}
                          keyName="languages"
                          options={LANGUAGES}
                          label="Languages"
                          placeholder="Search languages"
                          onChange={handleFormFieldChange}
                        />
                      </Box>
                      <Box sx={{mt:2, display: "none"}} className="zodiac-signs">
                        <MultiSelect
                          value={zodiacSigns}
                          keyName="zodiacSigns"
                          options={ZODIAC_SIGNS}
                          label="Zodiac Signs"
                          placeholder="Search zodiac signs"
                          onChange={handleFormFieldChange}
                        />
                      </Box>
                      <Box sx={{mt: 2}} className="slider-box">
                        <Stack direction={{"xs": "column", "md": "row"}}>
                          <Box paddingRight={{"xs": 0, "md": 1}} flexGrow={1} maxWidth={{"xs": "100%", "md": "50%"}}>
                            <InputSlider
                              icon={<AbcIcon fontSize='large'/>}
                              label="Name length cap"
                              min={4}
                              max={15}
                              step={1}
                              defaultValue={Number(alphabetCount)}
                              keyName="alphabetCount"
                              handleInputSliderChange={handleFormFieldChange}/>
                          </Box>
                          <Box paddingLeft={{"xs": 0, "md": 1}} flexGrow={1}>
                            <InputSlider
                              icon={<FormatListNumberedIcon fontSize='large'/>}
                              label="Number of names"
                              min={15}
                              max={50}
                              step={5}
                              defaultValue={Number(numberOfRecords)}
                              keyName="numberOfRecords"
                              handleInputSliderChange={handleFormFieldChange}/>
                          </Box>
                        </Stack>
                      </Box>
                    </ExpandableSection>
                  </Box>

                  <Box sx={{mt:2}} className="submit">
                    <LoadingButton
                      loading={loading}
                      type="submit"
                      color="primary"
                      fullWidth
                      variant="contained"
                      loadingPosition="start"
                      startIcon={<SearchIcon />}
                    >
                      Find Baby Names
                    </LoadingButton>
                  </Box>
                </form>
              </Box>
            </Paper>
          </Box>
          {(myNameList.length !== 0 || nameList.length !== 0) && (<Box component="section" ml={{"xs": 0, "md": 2}}  mt={{"xs": 4, "md": 0}} sx={{flexGrow:1}} minWidth={{"xs": "100%", "md": "350px", "lg": "400px"}} className="display-section">
            <Stack>
              {myNameList.length !== 0 && (
                <Paper elevation={3} sx={{minHeight: "200px", maxHeight: "calc(50vh - 100px)", overflow: "auto"}}>
                  <Box px={{xs: 2, sm: 4}} pt={2} pb={4}>
                    <CheckboxListSecondary
                        label="Favorite Baby Names"
                        icon={<DeleteOutline fontSize="medium"/>}
                        keyName="myNameList"
                        nameList={myNameList}
                        onChange={handleCheckboxMyListOnChange}
                      />
                  </Box>
                </Paper>
              )}
              {myNameList.length !== 0 && nameList.length !== 0 && <Divider orientation="horizontal" flexItem sx={{my: 2}}></Divider>}
              {nameList.length !== 0 && (
                <Paper elevation={3} sx={{overflow: "auto", flexFlow:1}}>
                  <Box ref={nameListSectionRef} px={{xs: 2, sm: 4}} pt={2} pb={4}>
                    <CheckboxListSecondary
                      label="Recommended Baby Names"
                      icon={<FavoriteBorder fontSize="medium" />}
                      headingIcon={<RefreshIcon fontSize="large"/>}
                      loading={loading}
                      keyName="nameList"
                      nameList={nameList}
                      onChange={handleCheckboxListOnChange}
                      handleIconButtonClick={submitFormData}
                    />
                  </Box>
                </Paper>
              )}
            </Stack>
          </Box>)}
        </Stack>
        <Snackbar open={apiError} autoHideDuration={600000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          We are experiencing an issue retrieving names at the moment. Please try again later or contact support if the issue persists.
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default CreateName;
