import { Autocomplete, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { COUNTRIES_DEFAULTS } from "../../constants";

export interface CountrySelectOptionType {
  code: string;
  label: string;
  phone: string;
}
export type CountrySelectOptionOutputType = string | "";

interface CountrySelectProps {
  countryResetFlag?: boolean;
  keyName: string;
  label: string;
  error?: string | boolean;
  required?: boolean;
  placeholder?: string;
  options: CountrySelectOptionType[];
  defaultInputValue?: CountrySelectOptionOutputType;
  defaultOptionValue?: CountrySelectOptionType | null;
  onChange?: (keyName: string, value: CountrySelectOptionType) => void;
}

export const CountrySelect: React.FC<CountrySelectProps> = (props) => {
  // const classes = useStyles();
  const {
    keyName,
    options,
    label,
    defaultInputValue,
    defaultOptionValue,
    onChange,
    required,
    error,
    placeholder,
    countryResetFlag
  } = props;
  const [value, setValue] = useState<CountrySelectOptionType | null>(
    defaultOptionValue || null
  );
  const [inputValue, setInputValue] = useState<CountrySelectOptionOutputType>(defaultInputValue || "");

  const getOptionLabel = (option: CountrySelectOptionType) => option.label;
  const isOptionEqualToValue = (option: CountrySelectOptionType, value: CountrySelectOptionType | null) => {
    if (!value) {
      return false;
    }
    return option.code === value.code && option.label === value.label && option.phone === value.phone;
  };

  useEffect(() => {
    setValue(COUNTRIES_DEFAULTS.optionValue);
    setInputValue(COUNTRIES_DEFAULTS.inputValue);
  }, [countryResetFlag]);

  return (
    <Box>
      <Typography color="textSecondary" sx={{mb:1}}>{label}</Typography>
      <Autocomplete
        id="country-select-demo"
        fullWidth
        value={value}
        onChange={(event: any, newValue: CountrySelectOptionType | null) => {
          console.log("Autocomplete onChange = ", newValue);
          setValue(newValue);
          if (onChange && newValue) {
            onChange(keyName, newValue);
          }
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options}
        autoHighlight
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              alt=""
            />
            {option.label} ({option.code})
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            // label={label}
            placeholder={placeholder}
            required={required}
            error={!!error}
            helperText={error}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </Box>
  );
};