import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import { NameObject } from "../../util/namesParserUtil";
import { stringAvatar } from "../../util/avatarUtil";
import { AppBar, AvatarGroup, Stack, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { styled } from "@mui/material/styles";
import { LoadingButton } from '@mui/lab';

// const CustomAppBar = styled(AppBar)({
const CustomAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: theme.palette.background.paper,
  backgroundImage: theme.palette.background.level1,
  color: "text.primary",
  "& .MuiToolbar-root": {
    justifyContent: "space-between",
    padding: 0
  },
}));

export type CheckboxListOptionType = string;

interface CheckboxListSecondaryProps {
  label: string,
  icon?: React.ReactNode,
  headingIcon?: React.ReactNode,
  keyName: string,
  nameList: NameObject[],
  onChange?: (keyName: string, value: CheckboxListOptionType[]) => void
  handleIconButtonClick?: () => void
  loading?: boolean
}

export const CheckboxListSecondary: React.FC<CheckboxListSecondaryProps> = (props) => {
  const { keyName, nameList, onChange, icon, label, headingIcon, handleIconButtonClick, loading } = props;
  const [checked, setChecked] = React.useState<CheckboxListOptionType[]>([]);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    if (onChange) {
      onChange(keyName, newChecked);
      setChecked([]);
    }
  };

  const getListItem = (name: NameObject, index: number) => {
    const { name: babyName, meaning, gender, origin } = name;
    return (<React.Fragment>
      <ListItemAvatar>
        <Avatar
          alt={`Avatar n°${index + 1}`}
          {...stringAvatar(babyName)}
        />
      </ListItemAvatar>
      <ListItemText
        primary={babyName}
        secondary={
          <React.Fragment>
            <Typography color="text.secondary" variant="body2" component="div">
               {`${meaning}`}{` - ${origin} origin`}
            </Typography>
            {gender && (<Typography sx={{ mb: 1 }} color="text.secondary" variant="body2" component="div">
              Gender: {gender}
            </Typography>)}
          </React.Fragment>
        }
      />
    </React.Fragment>)
  }

  const getListItemGroup = (names: NameObject[], index: number) => {
    return (<React.Fragment>
        <ListItemAvatar>
          <AvatarGroup>
            <Avatar
              alt={`Avatar n°${index + 1}`}
              {...stringAvatar(names[0].name)}
            />
            <Avatar
              alt={`Avatar n°${index + 1}`}
              {...stringAvatar(names[1].name)}
            />
          </AvatarGroup>
        </ListItemAvatar>
        <ListItemText
          sx={{"textTransform": "capitalize", ml: 2}}
          primary={`${names[0].name} - ${names[1].name}`}
          secondary={
            <React.Fragment>
              <Typography color="text.secondary" variant="body2" component="div">
                {names[0].meaning} - {names[1].meaning}
              </Typography>
              <Typography color="text.secondary" variant="body2" component="div">
                {names[0].origin} origin - {names[1].origin} origin
              </Typography>
              {names[0].gender && (<Typography sx={{ mb: 1 }} color="text.secondary" variant="body2" component="div">
                Gender: {names[0].gender}
              </Typography>)}
            </React.Fragment>
          }
        />
    </React.Fragment>)
  }

  return (
    <Box>
      <CustomAppBar position="sticky">
        <Toolbar disableGutters>
          <Stack direction="row" justifyContent="space-between" width= "100%" alignItems="center">
            <Typography
              variant="h6"
              component="h3"
              color="textSecondary"
            >
              {label}
            </Typography>
            {headingIcon && (
              <LoadingButton
                onClick={handleIconButtonClick}
                loading={loading}
                color="secondary"
                size="large"
                sx={{mr: -1}}
              >
                {headingIcon}
              </LoadingButton>
             )
            }
          </Stack>
        </Toolbar>
      </CustomAppBar>
      <List dense sx={{ width: '100%' }}>
        {nameList.map((names, index) => {
          const labelId = `checkbox-list-secondary-label-${index}`;
          const id = Array.isArray(names) ? names[0].id : names.id;
          return (
            <ListItem
              key={id}
              secondaryAction={
                <Checkbox
                  disabled={id === "99999999999999"}
                  icon={icon}
                  edge="end"
                  onChange={handleToggle(id)}
                  checked={checked.indexOf(id) !== -1}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              }
              disablePadding
            >
              <ListItemButton disableGutters>
                {Array.isArray(names) && getListItemGroup(names, index)}
                {!Array.isArray(names) && getListItem(names, index)}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}