import { Typography } from "@mui/material";
import Link from '@mui/material/Link';

export default function Copyright(props: any) {
  return (
    <Typography variant="body2" align="center" {...props} sx={{mt: 2, mb: 1, color: "common.white"}}>
      <Link  sx={{color: "common.white"}} href="https://nameadvisorai.com/" underline="none">
        NameAdvisorAI.com
      </Link>{' '}
      {' © '}
      {new Date().getFullYear()}
    </Typography>
  );
}